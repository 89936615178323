import "./set-public-path";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";



//export components from ui library
export {DarkToggle} from './components/ToggleThemeMode/index';
export {SectionTitle} from './components/SectionTitle/index';
export {LColor} from "./assets/icons/L/LColor"
export {LHL} from "./assets/icons/LHL/LHL"


//export components from external libraries
export { GiBookmarklet } from "react-icons/gi";
export { FaAngleDown,FaPencilAlt, FaTrash, FaUsers, FaBookReader, FaLandmark, FaAngleRight, FaStar, FaRegStar, FaSearch, FaLink } from "react-icons/fa";
export {RiLogoutBoxRLine} from "react-icons/ri"
export {GiEvilBook, GiChessKing, GiChessQueen, GiChessPawn} from "react-icons/gi"

export {IoMdAdd} from "react-icons/io"


export { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


export { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput1 from 'react-phone-number-input/input'
export const PhoneInput=PhoneInput1
import ReactTooltip1 from "react-tooltip";
export const ReactTooltip=ReactTooltip1
// create a navigation component that wraps the burger menu
export { NavLink, Redirect, BrowserRouter, Switch, Route, Link, useLocation } from "react-router-dom";
export {isMobile, isMobileOnly} from 'react-device-detect';

import Select1 from 'react-select'
import makeAnimated1 from 'react-select/animated';
export const Select=Select1
export const makeAnimated=makeAnimated1

import Modal1 from 'react-bootstrap/Modal';
export const Modal=Modal1
import Alert1 from 'react-bootstrap/Alert';
export const Alert=Alert1
import Accordion1 from 'react-bootstrap/Accordion';
export const Accordion=Accordion1
import Card1 from 'react-bootstrap/Card';
export const Card=Card1
import DataTable1 from 'react-data-table-component';
export const DataTable =DataTable1

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone1 from 'react-dropzone-uploader'
export const Dropzone=Dropzone1


import Tree1 from '@naisutech/react-tree'
export const Tree = Tree1

export {scroller, Element} from "react-scroll"

export {CountUpBlock} from "./components/CountUp/index"

import Collapse1 from 'react-bootstrap/Collapse'
export const Collapse=Collapse1
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
